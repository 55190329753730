import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { MainLayout } from '../components/Layout';
import queryString from 'query-string';
import has from 'lodash/has';

/**
 * @param data this prop will be injected by the GraphQL query below
 */
export default function Template({ location, data }) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  const [fromMobileApp, setFromMobileApp] = useState(false);
  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    const validParams = queryString.parse(location.search);

    if (has(validParams, 'platform')) {
      setPlatform(validParams.platform);
    }
  }, [location]);

  useEffect(() => {
    setFromMobileApp(
      platform && (platform === 'ANDROID' || platform === 'IOS') ? true : false,
    );
  }, [platform]);

  return (
    <MainLayout siteTitle={frontmatter.title} fromMobileApp={fromMobileApp}>
      <section className="container">
        <div className="row">
          <div className="col-12 text-align-center">
            <h1 className="roboto bold mt-10">{frontmatter.title}</h1>

            {frontmatter.ignoreUpdate === true ? (
              ''
            ) : (
              <div className="font-size-md">
                Date de dernière modification : {frontmatter.updatedAt}
              </div>
            )}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 pr-2 pl-2">
            <div
              className="html-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </section>
    </MainLayout>
  );
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        path
        updatedAt(formatString: "DD/MM/YYYY")
        ignoreUpdate
      }
    }
  }
`;
